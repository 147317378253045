import React, { FC, useCallback, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { SelectDateRangeMenu } from '../SelectDateRange';
import { observer } from 'mobx-react-lite';
import { EmployeePaymentStore } from '../../store/EmployeePaymentStore';
import { useRootStore } from '../../store/MobxStoreProvider';
import { DateRange } from '../SelectDateRange/DateRange';
import { RangeVariant } from '../SelectDateRange/MenuButton';
import { formatDateSwagger, formatNumberSpacing, formatPriceCur } from '@mx-ui/helpers';
import { Link as MxLink } from '@mx-ui/ui';

export const formatValueForPayment = (value = 0, withO = false): string => {
  if (!value && withO) return '0 ₽';
  if (!value && !withO) return 'TBD';
  const val = String(Math.round(parseFloat('' + value) * 100) / 100 || 0);
  return val.length <= 9 ? formatPriceCur(val) : formatNumberSpacing(val) + ` ₽`;
};

export const IndicatorsPanel: FC<{ isGridEditing: boolean }> = observer(({ isGridEditing }) => {
  const rootStore = useRootStore();
  const employeesPayment = useMemo(() => {
    const date = new Date();
    const store = new EmployeePaymentStore(rootStore);
    store.setDate(new Date(date.getFullYear(), date.getMonth(), 1), new Date(date.getFullYear(), date.getMonth() + 1, 0));
    store.initialLoadPayment();
    return store;
  }, [rootStore]);

  const handleRagePeriod = useCallback((rangeVariant: RangeVariant, range: DateRange): void => {
    if (employeesPayment?.selectedCrumbs[employeesPayment?.role]?.code) {
      employeesPayment.setDate(range.start, range.end);
      employeesPayment.setRequestFromObj({
        ...employeesPayment.request,
        quickRange: rangeVariant.value,
        dateFrom: formatDateSwagger(range.start),
        dateTo: formatDateSwagger(range.end),
      });
      employeesPayment.loadStatBudgetGroups(employeesPayment?.role);
    }
  }, []);

  const dateQuery = useMemo(
    () =>
      `&quickRange=${employeesPayment.request.quickRange || 'current_month'}&dateFrom=${
        employeesPayment._dateFormatPayment.from || ''
      }&dateTo=${employeesPayment._dateFormatPayment.to || ''}`,
    [employeesPayment._dateFormatPayment.from, employeesPayment._dateFormatPayment.to]
  );

  return (
    <>
      <Paper sx={{ height: '100%', width: '100%', padding: 1 }}>
        <Grid container flexDirection="column" flexWrap="nowrap" justifyContent="space-between" height="100%">
          <Grid item>
            <Grid container>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={12}>
                    <MxLink
                      href={isGridEditing ? undefined : `/app/report/payment?tab=transactions${dateQuery}&mode=${employeesPayment.mode}`}
                      variant="h6"
                      color="inherit"
                      typographySx={{ cursor: isGridEditing ? 'initial' : 'pointer' }}
                    >
                      График платежей
                    </MxLink>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} mb={1}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <SelectDateRangeMenu
                      onDateRangeSelect={(rangeVariant, range) => handleRagePeriod(rangeVariant, range)}
                      defaultQuickRangeValue={'current_month'}
                      disabled={isGridEditing}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={12}>
                <Grid item container flexDirection="column">
                  <Grid container justifyContent="space-between" alignItems="center" mb={1}>
                    <Grid item>
                      <MxLink href={`/app/report/payment?tab=transactions${dateQuery}`} typographySx={{ color: '#1E880F' }}>
                        Оплачено
                      </MxLink>
                    </Grid>
                    <Grid item>
                      <MxLink href={`/app/report/payment?tab=transactions${dateQuery}`} typographySx={{ color: '#1E880F' }}>
                        {formatValueForPayment(
                          !!employeesPayment?.paymentStats.length && employeesPayment?.paymentStats[0]?.report?.paidAmount,
                          true
                        )}
                      </MxLink>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between" alignItems="center" mb={1}>
                    <Grid item>
                      <MxLink href={`/app/report/payment?tab=awaiting${dateQuery}`} typographySx={{ color: '#6A6162' }}>
                        Ожидание
                      </MxLink>
                    </Grid>
                    <Grid item>
                      <MxLink href={`/app/report/payment?tab=awaiting${dateQuery}`} typographySx={{ color: '#6A6162' }}>
                        {formatValueForPayment(
                          !!employeesPayment?.paymentStats.length && employeesPayment?.paymentStats[0]?.report?.awaitingAmount,
                          true
                        )}
                      </MxLink>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <MxLink href={`/app/report/payment?tab=overdue${dateQuery}`} typographySx={{ color: '#FC090E' }}>
                        Просрочено
                      </MxLink>
                    </Grid>
                    <Grid item>
                      <MxLink href={`/app/report/payment?tab=overdue${dateQuery}`} typographySx={{ color: '#FC090E' }}>
                        {formatValueForPayment(
                          !!employeesPayment?.paymentStats.length && employeesPayment?.paymentStats[0]?.report?.overdueAmount,
                          true
                        )}
                      </MxLink>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item></Grid>
          <Grid item></Grid>
        </Grid>
      </Paper>
    </>
  );
});
