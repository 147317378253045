import React, { useEffect, useRef, useState } from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import Modal from './Modal';
export type QuickDateRangesType =
  | 'today'
  | 'yesterday'
  | 'current_week'
  | 'previous_week'
  | 'two_weeks'
  | 'current_month'
  | 'previous_month'
  | 'custom_period';

export type RangeVariant = {
  value: QuickDateRangesType;
  text: string;
};

import { DateRange } from './DateRange';
import {
  convertToDate,
  getCurrentMonthRange,
  getCurrentWeekRange,
  getDateRangeFromNow,
  getPrevMonthRange,
  getPrevWeekRange,
  getWeekRangeByQuantity,
} from '@mx-ui/helpers';

const quickDateRanges: RangeVariant[] = [
  // {
  //   value: 'today',
  //   text: 'Сегодня',
  // },
  // {
  //   value: 'yesterday',
  //   text: 'Вчера',
  // },
  // {
  //   value: 'current_week',
  //   text: 'За текущую неделю',
  // },
  // {
  //   value: 'previous_week',
  //   text: 'За предыдущую неделю',
  // },
  {
    value: 'two_weeks',
    text: 'За две недели',
  },
  {
    value: 'current_month',
    text: 'За текущий месяц',
  },

  {
    value: 'previous_month',
    text: 'За предыдущий месяц',
  },
  {
    value: 'custom_period',
    text: 'Выбрать период...',
  },
];

const getDatesFromQuickRange = (timeRange: RangeVariant): DateRange => {
  if (timeRange.value === 'current_month') {
    return getCurrentMonthRange();
  } else if (timeRange.value === 'previous_month') {
    return getPrevMonthRange();
  } else if (timeRange.value === 'two_weeks') {
    return getWeekRangeByQuantity(2);
  } else if (timeRange.value === 'previous_week') {
    return getPrevWeekRange();
  } else if (timeRange.value === 'current_week') {
    return getCurrentWeekRange();
  } else if (timeRange.value === 'yesterday') {
    return getDateRangeFromNow(1, false);
  }
  return getDateRangeFromNow(0, true);
};

interface MenuButtonOnDateRangeSelect {
  (rangeVariant: RangeVariant, value: DateRange): void;
}

type MenuButtonProps = {
  onDateRangeSelect: MenuButtonOnDateRangeSelect;
  defaultQuickRangeValue: string;
  defaultStart?: Date;
  defaultEnd?: Date;
  rangeModalText?: string;
  rangeModalStartDateDefault?: Date;
  rangeModalEndDateDefault?: Date;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  period?: { from: string; to: string };
  disabled?: boolean;
};
/**
 * @deprecated use MenuButtonNew
 */
const MenuButton = ({
  onDateRangeSelect,
  defaultQuickRangeValue = 'current_week',
  defaultStart = null,
  defaultEnd = null,
  rangeModalText = 'Укажите период',
  rangeModalStartDateDefault = null,
  rangeModalEndDateDefault = null,
  color = 'primary',
  period = undefined,
  disabled,
}: MenuButtonProps): JSX.Element => {
  const actionRef = useRef<any>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const [quickRange, setQuickRange] = useState<RangeVariant>(quickDateRanges.find(t => t.value === defaultQuickRangeValue));

  const [selectedQuickRangeText, setSelectedQuickRangeText] = useState<string>(
    quickDateRanges.find(t => t.value === defaultQuickRangeValue).text
  );

  useEffect(() => {
    if (defaultQuickRangeValue === 'custom_period' && defaultStart && defaultEnd) {
      handleSetDateRange(quickRange, { start: defaultStart, end: defaultEnd });
    } else {
      const range = getDatesFromQuickRange(quickRange);
      handleSetDateRange(quickRange, range);
    }
  }, []);

  useEffect(() => {
    if (period?.from && period?.to && quickRange.value === 'custom_period') {
      setSelectedQuickRangeText(`период с ${period.from} по ${period.to}`);
    }
  }, [period?.from, period?.to, quickRange]);

  const handleModalClose = (): void => {
    setIsModalOpen(false);
  };

  // SetDateRange

  const handleModalSave = (range: DateRange): void => {
    const newQuickRange = quickDateRanges.find(r => r.value === 'custom_period');

    setQuickRange(newQuickRange);
    handleSetDateRange(newQuickRange, range);
  };

  const handleSetDateRange = (rangeVariant: RangeVariant, range: DateRange): void => {
    if (rangeVariant.value === 'custom_period') {
      setSelectedQuickRangeText(`период с ${convertToDate(range.start)} по ${convertToDate(range.end)}`);
      onDateRangeSelect(rangeVariant, range);
    } else {
      const value = getDatesFromQuickRange(rangeVariant);
      onDateRangeSelect(rangeVariant, {
        start: value.start,
        end: value.end,
      } as DateRange);
    }
  };

  const handleMenuItemClick = (selectedTimeRange: RangeVariant): void => {
    if (selectedTimeRange.value === 'custom_period') {
      setIsModalOpen(true);
    } else {
      setQuickRange(selectedTimeRange);
      setSelectedQuickRangeText(selectedTimeRange.text);

      const range = getDatesFromQuickRange(selectedTimeRange);
      handleSetDateRange(selectedTimeRange, range);
    }
    setMenuOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSave={handleModalSave}
        text={rangeModalText}
        startDateDefault={rangeModalStartDateDefault}
        endDateDefault={rangeModalEndDateDefault}
      />
      <Button
        ref={actionRef}
        onClick={() => setMenuOpen(true)}
        color={color}
        disabled={!!disabled}
        startIcon={
          <SvgIcon fontSize="small">
            <CalendarTodayIcon />
          </SvgIcon>
        }
      >
        {selectedQuickRangeText}
      </Button>
      <Menu
        anchorEl={actionRef.current}
        onClose={() => setMenuOpen(false)}
        open={isMenuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ zIndex: 1500 }}
      >
        {quickDateRanges.map(_timeRange => (
          <MenuItem key={_timeRange.value} onClick={() => handleMenuItemClick(_timeRange)}>
            {_timeRange.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuButton;
