import { RootStore } from './StoreManager';
import { makeAutoObservable, observable, runInAction, set, toJS } from 'mobx';
import { ApiStore } from './Global/ApiStore';
import { SnackbarStore } from './SnackbarStore';
import { AuthStore } from './AuthStore';
import { Code, getPropertyByEntity } from './EmployeesMarginalityStore';
import { AppUserGroupCodes } from '../types/AppUserGroup';
import { formatDateSwagger, formatDateSwaggerZ, formatDateToISOStringWithTimezone } from '@mx-ui/helpers';
import { AxiosResponse } from 'axios';
import {
  PaymentBranchOffice,
  PaymentExpectation,
  PaymentTransaction,
  PaymentTransactionsResponse,
  Plot as PlotRequest,
  StatPaymentsDepartmentsResponse,
  StatPaymentsDivisionReport,
  StatPaymentsDivisionsResponse,
  StatPaymentsEmployeesResponse,
  StatPaymentsExpectationsResponse,
  StatPaymentsHeadCompaniesResponse,
  StatPaymentsOfficesResponse,
  StatPaymentsPlannedResponse,
  StatPaymentsReport,
  StatReferencesResponse,
} from '../api/marketx';
import { RouterStore } from './RouterStore';
import moment from 'moment';
import { Plot, PlotPoint } from '../lib/plots';
import { ParsedUrlQuery } from 'querystring';
export interface PaymentSelectedCrumb {
  code?: Code; //* должно быть обязательно
  title?: string;
}
const PaymentRoleEmployeeTypes = <const>['company', 'division', 'office', 'department', 'employee'];
export type PaymentRoleEmployee = (typeof PaymentRoleEmployeeTypes)[number];
export type CustomerPaymentStatItemType = {
  awaitingAmount?: number;
  billMdmCode?: string;
  customerCode?: string;
  paidAmount?: number;
  billDate?: string;
  plannedDate?: string;
  overdueAmount?: number;
  postpayDays?: number;
  prepayPct?: number;
  shipmentDocumentNumber?: string;
};
export type CustomersPaymentStatType = Record<
  Code,
  {
    totalAwaitingAmount: number;
    totalPaidAmount: number;
    totalOverdueAmount?: number;
    shortTitle?: string;
    mainEmployee?: string;
    companyFoundedDate?: string;
    notDistributedAmount?: number;
    bills: Record<Code, Array<CustomerPaymentStatItemType>> | null;
  }
>;
const PaymentTabNamesTypes = <const>['transactions', 'clients', 'awaiting', 'overdue'];
export type PaymentTabNames = (typeof PaymentTabNamesTypes)[number];

export const PaymentTabNamesValue: Record<PaymentTabNames, string> = {
  transactions: 'Оплачено',
  awaiting: 'Ожидание',
  clients: 'Клиенты',
  overdue: 'Просрочено',
};

export const PAYMENT_ROLE_EMPLOYEE_CODES = {
  employee: 'employee' as PaymentRoleEmployee,
  department: 'department' as PaymentRoleEmployee,
  division: 'division' as PaymentRoleEmployee,
  company: 'company' as PaymentRoleEmployee,
  office: 'office' as PaymentRoleEmployee,
};

export const PAYMENT_TAB_NAME_CODES = {
  transactions: 'transactions' as PaymentTabNames,
  awaiting: 'awaiting' as PaymentTabNames,
  clients: 'clients' as PaymentTabNames,
  overdue: 'overdue' as PaymentTabNames,
};

const allowTabsByRole: Record<PaymentRoleEmployee, PaymentTabNames[]> = {
  employee: [
    PAYMENT_TAB_NAME_CODES.transactions,
    PAYMENT_TAB_NAME_CODES.awaiting,
    PAYMENT_TAB_NAME_CODES.overdue,
    PAYMENT_TAB_NAME_CODES.clients,
  ],
  department: [
    PAYMENT_TAB_NAME_CODES.transactions,
    PAYMENT_TAB_NAME_CODES.awaiting,
    PAYMENT_TAB_NAME_CODES.overdue,
    PAYMENT_TAB_NAME_CODES.clients,
  ],
  office: [PAYMENT_TAB_NAME_CODES.transactions, PAYMENT_TAB_NAME_CODES.awaiting, PAYMENT_TAB_NAME_CODES.overdue],
  division: [],
  company: [],
};

export interface AllowedParams {
  tab?: PaymentTabNames;
  selectedCrumbCode?: string;
  isFirstChartDecorated?: string;
  isSecondChartDecorated?: string;
  isThirdChartDecorated?: string;
  dateFrom?: string;
  dateTo?: string;
  quickRange?: string;
  mode?: PaymentRoleEmployee;
}
export const namesCrumbsPaymentNoValue: Record<PaymentRoleEmployee, string> = {
  employee: 'Все менеджеры',
  department: 'Все отделы',
  office: 'Все филиалы',
  division: 'Все дивизионы',

  company: '',
};

export function transformQueryToAllowedParamsForPayment<T>(query: ParsedUrlQuery, role?: PaymentRoleEmployee): T {
  const obj = {} as any;
  const date = new Date();
  if (query.mode && PaymentRoleEmployeeTypes.indexOf(query.mode as any) !== -1) {
    obj.mode = query.mode as PaymentRoleEmployee;
  } else {
    obj.mode = role;
  }
  if (
    query.tab &&
    query.mode &&
    PaymentTabNamesTypes.indexOf(query.tab as any) !== -1 &&
    allowTabsByRole[query.mode as string].indexOf(query.tab as any) !== -1
  ) {
    obj.tab = query.tab as PaymentTabNames;
  } else {
    obj.tab = PAYMENT_TAB_NAME_CODES.transactions;
  }
  if (query.selectedCrumbCode) {
    obj.selectedCrumbCode = query.selectedCrumbCode as string;
  }
  if (query.quickRange) {
    obj.quickRange = query.quickRange as string;
  } else {
    obj.quickRange = 'current_month';
  }
  if (query.isFirstChartDecorated) {
    obj.isFirstChartDecorated = query.isFirstChartDecorated as string;
  }
  if (query.isSecondChartDecorated) {
    obj.isSecondChartDecorated = query.isSecondChartDecorated as string;
  }
  if (query.isThirdChartDecorated) {
    obj.isThirdChartDecorated = query.isThirdChartDecorated as string;
  }
  if (query.dateFrom) {
    obj.dateFrom = query.dateFrom as string;
  } else {
    obj.dateFrom = new Date(date.getFullYear(), date.getMonth(), 1);
  }
  if (query.dateTo) {
    obj.dateTo = query.dateTo as string;
  } else {
    obj.dateTo = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }
  return obj;
}

export class EmployeePaymentStore {
  apiStore: ApiStore;
  snackbarStore: SnackbarStore;
  authStore: AuthStore;
  routerStore: RouterStore;
  routerControlEnabled = false;
  request: AllowedParams = null;
  role: PaymentRoleEmployee;
  mode: PaymentRoleEmployee;
  allowTabs: PaymentTabNames[] = [];
  statPlot: Plot[] = [];

  isLoading = false;
  isTotalPaymentDataLoading = false;
  subjectsTotalPaymentDataByCode: Record<string, { title: string; awaitingAmount: number; overdueAmount: number; paidAmount: number }> =
    null;
  subjectsTotalReportsDataModeCode = '';
  isLoaded = false;
  chartWasChanged = false;
  selectedCrumbs: Record<PaymentRoleEmployee, PaymentSelectedCrumb> = {
    company: undefined,
    division: undefined,
    office: undefined,
    department: undefined,
    employee: undefined,
  };
  crumbsValues: Record<PaymentRoleEmployee, PaymentSelectedCrumb[]> = {
    company: [],
    division: [],
    office: [],
    department: [],
    employee: [],
  };
  currentCrumbsCode: string = null;
  enabledCrumbs: PaymentRoleEmployee[] = [];

  paymentStats: StatPaymentsDivisionReport[] = [];

  customersPaymentStatItemCount = 0;
  customersPaymentStatList: CustomersPaymentStatType = {};
  canLoadMore = true;
  expectationPage = 1;

  transactionsList: PaymentTransaction[] = null;
  transactionsOffices: PaymentBranchOffice[] = null;
  transactionsCount = 0;
  canLoadMoreTransactions = false;
  isTransactionsListLoading = true;
  transactionsPage = 1;

  awaitingList: PaymentExpectation[] = null;
  awaitingCustomersByCode: Array<Record<any, any>> = null;
  awaitingCount = 0;
  canLoadMoreAwaiting = false;
  isAwaitingListLoading = true;
  awaitingPage = 1;

  overdueList: PaymentExpectation[] = null;
  overdueCustomersByCode: Array<Record<any, any>> = null;
  overdueCount = 0;
  canLoadMoreOverdue = false;
  isOverdueListLoading = true;
  overduePage = 1;

  periodByMonth: Array<{ from: string; to: string }> = [];
  isCHartsWithPagination = false;
  isCHartsPaginationLoading = false;
  isCHartsLoading = false;
  chartsPaginationIndex = 0;
  isPeriodChangedByCharts = false;

  _dateFormatPayment = {
    from: '',
    to: '',
  };

  get paymentDateISO(): { from: string; to: string } {
    return {
      from: formatDateToISOStringWithTimezone(this._dateFormatPayment.from),
      to: formatDateToISOStringWithTimezone(this._dateFormatPayment.to),
    };
  }

  get paymentDateSwagger(): { from: string; to: string } {
    return {
      from: formatDateSwagger(this._dateFormatPayment.from),
      to: formatDateSwagger(this._dateFormatPayment.to),
    };
  }

  set paymentDate({ from, to }) {
    this._dateFormatPayment.from = formatDateSwagger(from);
    this._dateFormatPayment.to = formatDateSwagger(to);
  }

  selectedTab: PaymentTabNames = PAYMENT_TAB_NAME_CODES.transactions;

  constructor(rootStore: RootStore) {
    this.snackbarStore = rootStore.getSnackbar();
    this.apiStore = rootStore.getApiStore();
    this.authStore = rootStore.getAuth();
    this.role = this.getTypeByRole();
    this.routerStore = rootStore.getRouter();
    this.request = observable({});
    this.setMode(this.role);
    makeAutoObservable(this, { apiStore: false, snackbarStore: false, getTypeByRole: false }, { autoBind: true });
  }

  getTypeByRole(): PaymentRoleEmployee {
    const isDivisionChief = this.authStore.inGroup(AppUserGroupCodes.DIVISION_CHIEF);
    const isDivisionMarketer = this.authStore.inGroup(AppUserGroupCodes.DIVISION_MARKETER);
    const isCallCenterOperator = this.authStore.inGroup(AppUserGroupCodes.CALL_CENTER_OPERATOR);
    const isOfficeChief = this.authStore.inGroup(AppUserGroupCodes.OFFICE_CHIEF);
    const isOfficeMarketer = this.authStore.inGroup(AppUserGroupCodes.OFFICE_MARKETER);
    const isSellerChief = this.authStore.inGroup(AppUserGroupCodes.SELLER_CHIEF);
    const isCompanyChief = this.authStore.inGroup(AppUserGroupCodes.COMPANY_CHIEF);
    const isCompanyMarketer = this.authStore.inGroup(AppUserGroupCodes.COMPANY_MARKETER);
    const isSupport = this.authStore.inGroup(AppUserGroupCodes.SUPPORT);

    if (isCompanyChief || isCompanyMarketer) {
      return PAYMENT_ROLE_EMPLOYEE_CODES.company;
    }
    if (isDivisionChief || isDivisionMarketer) {
      return PAYMENT_ROLE_EMPLOYEE_CODES.division;
    }
    if (isOfficeChief || isSupport || isOfficeMarketer || isCallCenterOperator) {
      return PAYMENT_ROLE_EMPLOYEE_CODES.office;
    }
    if (isSellerChief) {
      return PAYMENT_ROLE_EMPLOYEE_CODES.department;
    }
    PAYMENT_ROLE_EMPLOYEE_CODES;
    return PAYMENT_ROLE_EMPLOYEE_CODES.employee;
  }

  setMode(mode: PaymentRoleEmployee): boolean {
    if (mode === this.mode) {
      return false;
    }
    const roleIndex = PaymentRoleEmployeeTypes.indexOf(this.role);
    const modeParamIndex = PaymentRoleEmployeeTypes.indexOf(this.request.mode);
    if (roleIndex > modeParamIndex && this.request.mode) {
      console.warn('Нельзя установить mode выше роли. Значение mode установится по умолчанию');
      this.snackbarStore.showInfo('Вам недоступен просмотр этого уровня. Значения установятся по умолчанию');
      this.mode = this.role;
      return false;
    }
    this.setSelectedTab(this.selectedTab);

    this.mode = mode;

    return true;
  }
  setRouterControl(enabled: boolean): void {
    this.routerControlEnabled = enabled;
  }

  setDate(dateFrom: Date, dateTo: Date, byCharts = false): void {
    this.paymentDate = { from: dateFrom, to: dateTo };
    this.isPeriodChangedByCharts = byCharts;
  }

  async initialLoadPayment(): Promise<void> {
    const role = this.role;
    this.allowTabs = allowTabsByRole[role];
    await this.loadStatPaymentReferences({}, true);
    await this.loadStatBudgetGroups(role);
  }
  async initialLoadStatsByQuery(params: AllowedParams): Promise<void> {
    if ((this.role === 'division' || this.role === 'company') && !params.mode) {
      params.mode = 'office';
      params.selectedCrumbCode = this.authStore.profile.chosenBranchOfficeCode;
    }
    const role = this.role;
    await runInAction(async () => {
      this.setRequestFromObj(params);
      if (params.mode) {
        this.setMode(params.mode);
        this.allowTabs = allowTabsByRole[this.mode];
        await this.loadStatPaymentReferences({ [this.mode]: params.selectedCrumbCode }, true);
      } else {
        this.allowTabs = allowTabsByRole[role];
        await this.loadStatPaymentReferences({}, true);
      }

      this.allowTabs.forEach(async item => {
        await this.loadStatByTab(item);
      });
      if (params?.tab) {
        this.setSelectedTab(params.tab);
      }

      await this.loadStatBudgetGroups(this.mode);
      runInAction(() => {
        this.isCHartsLoading = true;
      });
      const currentPeriod = this.paymentDateISO;
      if (moment(currentPeriod.from).add(1, 'month').toDate() < new Date(currentPeriod.to)) {
        runInAction(() => {
          this.isCHartsWithPagination = true;
          this.chartsPaginationIndex = 0;
        });
        this.makeChartsInnerPeriods();
      } else {
        runInAction(() => {
          this.isCHartsWithPagination = false;
        });
        this.loadCharts();
      }
    });
  }
  loadPaymentsTransactions(role: PaymentRoleEmployee): void {
    switch (role) {
      case PAYMENT_ROLE_EMPLOYEE_CODES.office:
        this.loadOfficesPaymentsTransactions(this.selectedCrumbs[role]?.code);
        break;
      case PAYMENT_ROLE_EMPLOYEE_CODES.department:
        this.loadDepartmentsPaymentsTransactions(this.selectedCrumbs[role]?.code);
        break;
      case PAYMENT_ROLE_EMPLOYEE_CODES.employee:
        this.loadEmployeesPaymentsTransactions(this.selectedCrumbs[role]?.code);
        break;
      default:
        console.warn('loadPaymentsTransactions role received does not exist');
        break;
    }
  }
  loadPaymentsAwaiting(role: PaymentRoleEmployee): void {
    switch (role) {
      case PAYMENT_ROLE_EMPLOYEE_CODES.office:
        this.loadOfficesPaymentsAwaiting(this.selectedCrumbs[role]?.code);
        break;
      case PAYMENT_ROLE_EMPLOYEE_CODES.department:
        this.loadDepartmentsPaymentsAwaiting(this.selectedCrumbs[role]?.code);
        break;
      case PAYMENT_ROLE_EMPLOYEE_CODES.employee:
        this.loadEmployeesPaymentsAwaiting(this.selectedCrumbs[role]?.code);
        break;
      default:
        console.warn('loadPaymentsAwaiting role received does not exist');
        break;
    }
  }
  loadPaymentsOverdue(role: PaymentRoleEmployee): void {
    switch (role) {
      case PAYMENT_ROLE_EMPLOYEE_CODES.office:
        this.loadOfficesPaymentsOverdue(this.selectedCrumbs[role]?.code);
        break;
      case PAYMENT_ROLE_EMPLOYEE_CODES.department:
        this.loadDepartmentsPaymentsOverdue(this.selectedCrumbs[role]?.code);
        break;
      case PAYMENT_ROLE_EMPLOYEE_CODES.employee:
        this.loadEmployeesPaymentsOverdue(this.selectedCrumbs[role]?.code);
        break;
      default:
        console.warn('loadPaymentsOverdue role received does not exist');
        break;
    }
  }
  loadEmployeesPaymentsOverdue(employeeCode: Code): void {
    const currentDate = this.paymentDateISO;
    this.apiStore
      .apiStatApi()
      .statPaymentsPlanned({
        overdueOnly: true,
        employeeCodes: [employeeCode],
        from: currentDate.from,
        to: currentDate.to,
        page: this.overduePage,
        count: 20,
      })
      .then(res => {
        this.setPaymentsOverdueResult(res);
      })
      .catch(err => {
        console.warn('loadEmployeesPaymentsOverdue', err);
        this.isOverdueListLoading = false;
      });
  }
  loadDepartmentsPaymentsOverdue(departmentCode: Code): void {
    const currentDate = this.paymentDateISO;
    this.apiStore
      .apiStatApi()
      .statPaymentsPlanned({
        overdueOnly: true,
        departmentCodes: [departmentCode],
        from: currentDate.from,
        to: currentDate.to,
        page: this.overduePage,
        count: 20,
      })
      .then(res => {
        this.setPaymentsOverdueResult(res);
      })
      .catch(err => {
        console.warn('loadDepartmentsPaymentsOverdue', err);
        this.isOverdueListLoading = false;
      });
  }
  loadOfficesPaymentsOverdue(officeCode: Code): void {
    const currentDate = this.paymentDateISO;
    this.apiStore
      .apiStatApi()
      .statPaymentsPlanned({
        overdueOnly: true,
        branchOfficeCodes: [officeCode],
        from: currentDate.from,
        to: currentDate.to,
        page: this.overduePage,
        count: 20,
      })
      .then(res => {
        this.setPaymentsOverdueResult(res);
      })
      .catch(err => {
        console.warn('loadOfficesPaymentsOverdue', err);
        this.isOverdueListLoading = false;
      });
  }
  setPaymentsOverdueResult(data: AxiosResponse<StatPaymentsPlannedResponse>): void {
    if (this.overduePage === 1) {
      this.overdueList = data.data.plans;
      this.overdueCustomersByCode = [];
      this.overdueCount = data.data.plansTotalCount;
    } else {
      data.data.plans.forEach(item => this.overdueList.push(item));
    }

    data.data?.customers?.forEach(item => {
      if (!this.overdueCustomersByCode[item.code]) {
        this.overdueCustomersByCode[item.code] = {};
        this.overdueCustomersByCode[item.code].shortTitle = item.shortTitle;
        this.overdueCustomersByCode[item.code].title = item.title;
      }
    });
    this.canLoadMoreOverdue = this.overdueList?.length < this.overdueCount;
    this.isOverdueListLoading = false;
  }
  loadOfficesPaymentsAwaiting(officeCode: Code): void {
    const currentDate = this.paymentDateISO;
    this.apiStore
      .apiStatApi()
      .statPaymentsPlanned({
        awaitingOnly: true,
        branchOfficeCodes: [officeCode],
        from: currentDate.from,
        to: currentDate.to,
        page: this.awaitingPage,
        count: 20,
      })
      .then(res => {
        this.setPaymentsAwaitingResult(res);
      })
      .catch(err => {
        console.warn('loadOfficesPaymentsAwaiting', err);
        this.isAwaitingListLoading = false;
      });
  }
  loadEmployeesPaymentsAwaiting(employeeCode: Code): void {
    const currentDate = this.paymentDateISO;
    this.apiStore
      .apiStatApi()
      .statPaymentsPlanned({
        awaitingOnly: true,
        employeeCodes: [employeeCode],
        from: currentDate.from,
        to: currentDate.to,
        page: this.awaitingPage,
        count: 20,
      })
      .then(res => {
        this.setPaymentsAwaitingResult(res);
      })
      .catch(err => {
        console.warn('loadEmployeesPaymentsAwaiting', err);
        this.isAwaitingListLoading = false;
      });
  }
  loadDepartmentsPaymentsAwaiting(departmentCode: Code): void {
    const currentDate = this.paymentDateISO;
    this.apiStore
      .apiStatApi()
      .statPaymentsPlanned({
        awaitingOnly: true,
        departmentCodes: [departmentCode],
        from: currentDate.from,
        to: currentDate.to,
        page: this.awaitingPage,
        count: 20,
      })
      .then(res => {
        this.setPaymentsAwaitingResult(res);
      })
      .catch(err => {
        console.warn('loadDepartmentsPaymentsAwaiting', err);
        this.isAwaitingListLoading = false;
      });
  }
  loadEmployeesPaymentsTransactions(employeeCode: Code): void {
    const currentDate = this.paymentDateISO;
    const currentMonth = formatDateSwaggerZ(new Date().setDate(1));
    this.apiStore
      .apiClientPayment()
      .paymentTransactions({
        employeeCodes: [employeeCode],
        employeePeriod: currentMonth,
        dateTimeFrom: currentDate.from,
        dateTimeTo: currentDate.to,
        page: this.transactionsPage,
        count: 20,
      })
      .then(res => {
        this.setPaymentsTransactionsResult(res);
      })
      .catch(err => {
        console.warn('loadEmployeesPaymentsTransactions', err);
        this.isTransactionsListLoading = false;
      });
  }

  loadOfficesPaymentsTransactions(officeCode: Code): void {
    const currentDate = this.paymentDateISO;
    this.apiStore
      .apiClientPayment()
      .paymentTransactions({
        branchOfficeCodes: [officeCode],
        dateTimeFrom: currentDate.from,
        dateTimeTo: currentDate.to,
        page: this.transactionsPage,
        count: 20,
      })
      .then(res => {
        this.setPaymentsTransactionsResult(res);
      })
      .catch(err => {
        console.warn('loadOfficesPaymentsTransactions', err);
        this.isTransactionsListLoading = false;
      });
  }

  loadDepartmentsPaymentsTransactions(departmentCode: Code): void {
    const currentDate = this.paymentDateISO;
    const currentMonth = formatDateSwaggerZ(new Date().setDate(1));
    this.apiStore
      .apiClientPayment()
      .paymentTransactions({
        departmentCodes: [departmentCode],
        departmentPeriod: currentMonth,
        dateTimeFrom: currentDate.from,
        dateTimeTo: currentDate.to,
        page: this.transactionsPage,
        count: 20,
      })
      .then(res => {
        this.setPaymentsTransactionsResult(res);
      })
      .catch(err => {
        console.warn('loadDepartmentsPaymentsTransactions', err);
        this.isTransactionsListLoading = false;
      });
  }

  setPaymentsAwaitingResult(data: AxiosResponse<StatPaymentsPlannedResponse>): void {
    if (this.awaitingPage === 1) {
      this.awaitingList = data.data.plans;
      this.awaitingCustomersByCode = [];
      this.awaitingCount = data.data.plansTotalCount;
    } else {
      data.data.plans.forEach(item => this.awaitingList.push(item));
    }

    data.data?.customers?.forEach(item => {
      if (!this.awaitingCustomersByCode[item.code]) {
        this.awaitingCustomersByCode[item.code] = {};
        this.awaitingCustomersByCode[item.code].shortTitle = item.shortTitle;
        this.awaitingCustomersByCode[item.code].title = item.title;
      }
    });

    this.canLoadMoreAwaiting = this.awaitingList?.length < this.awaitingCount;
    this.isAwaitingListLoading = false;
  }
  setPaymentsTransactionsResult(data: AxiosResponse<PaymentTransactionsResponse>): void {
    if (this.transactionsPage === 1) {
      this.transactionsList = data.data.transactions;
      this.transactionsOffices = data.data.transactionsBranchOffices;
      this.transactionsCount = data.data.transactionsTotalCount;
    } else {
      data.data.transactions.forEach(item => this.transactionsList.push(item));
    }
    this.canLoadMoreTransactions = this.transactionsList?.length < this.transactionsCount;
    this.isTransactionsListLoading = false;
  }
  async loadStatBudgetGroups(role: PaymentRoleEmployee): Promise<void> {
    switch (role) {
      case PAYMENT_ROLE_EMPLOYEE_CODES.division:
        await this.loadStatPaymentDivisions(this.selectedCrumbs[role]?.code);
        break;
      case PAYMENT_ROLE_EMPLOYEE_CODES.office:
        await this.loadStatPaymentOffices(this.selectedCrumbs[role]?.code);
        break;
      case PAYMENT_ROLE_EMPLOYEE_CODES.department:
        await this.loadStatPaymentDepartments(this.selectedCrumbs[role]?.code);
        break;
      case PAYMENT_ROLE_EMPLOYEE_CODES.employee:
        await this.loadStatPaymentEmployees(this.selectedCrumbs[role]?.code);
        break;
      case PAYMENT_ROLE_EMPLOYEE_CODES.company:
        await this.loadStatPaymentCompany();
        break;

      default:
        console.warn('role received does not exist');
        break;
    }
  }

  loadCharts(): void {
    const role = this.mode || this.role;
    this.loadChartsByRole(role);
  }

  makeChartsInnerPeriods(): void {
    const currentPeriod = this.paymentDateISO;
    let startDate = new Date(currentPeriod.from);
    let endDate = new Date(currentPeriod.to);
    const result: Array<{ from: string; to: string }> = [];
    while (moment(startDate).add(1, 'month').subtract(1, 'day').toDate() < new Date(currentPeriod.to)) {
      const intoMonth = moment(startDate).add(1, 'month').subtract(1, 'day').toDate();
      result.push({
        from: formatDateToISOStringWithTimezone(startDate),
        to: formatDateToISOStringWithTimezone(intoMonth),
      });
      startDate = moment(intoMonth).add(1, 'day').toDate();
      endDate = moment(startDate).add(1, 'month').toDate();
    }
    if (endDate >= new Date(currentPeriod.to)) {
      result.push({
        from: formatDateToISOStringWithTimezone(startDate),
        to: currentPeriod.to,
      });
    }
    this.periodByMonth = result;

    this.loadCharts();
  }
  setNewPaginationIndex(pageNumber: number): void {
    this.isCHartsPaginationLoading = true;
    this.chartsPaginationIndex = pageNumber;
    this.loadCharts();
  }
  loadChartsByRole(role: PaymentRoleEmployee): void {
    switch (role) {
      case PAYMENT_ROLE_EMPLOYEE_CODES.division:
        this.loadStatPaymentPlotDivisions(this.selectedCrumbs[role]?.code);
        break;
      case PAYMENT_ROLE_EMPLOYEE_CODES.office:
        this.loadStatPaymentPlotOffices(this.selectedCrumbs[role]?.code);
        break;
      case PAYMENT_ROLE_EMPLOYEE_CODES.department:
        this.loadStatPaymentPlotDepartments(this.selectedCrumbs[role]?.code);
        break;
      case PAYMENT_ROLE_EMPLOYEE_CODES.employee:
        this.loadStatPaymentPlotEmployees(this.selectedCrumbs[role]?.code);
        break;
      case PAYMENT_ROLE_EMPLOYEE_CODES.company:
        this.loadStatPaymentPlotCompany();
        break;

      default:
        console.warn('loadChartsByRole request');
        runInAction(() => {
          this.isLoading = false;
        });
        break;
    }
  }

  loadStatPaymentPlotDivisions(divisionCode: Code): void {
    const currentDate = this.isCHartsWithPagination ? this.periodByMonth[this.chartsPaginationIndex] : this.paymentDateISO;
    this.apiStore
      .apiStatApi()
      .statPaymentsDivisionsPlot(divisionCode, undefined, undefined, currentDate.from, currentDate.to)
      .then(res => {
        this.setResultPaymentPlotDivisions(res.data.statPlot);
      })
      .catch(err => {
        console.warn('loadStatPaymentPlotDivisions', err);
      });
  }
  loadStatPaymentPlotOffices(branchOfficeCode: Code = undefined): void {
    const currentDate = this.isCHartsWithPagination ? this.periodByMonth[this.chartsPaginationIndex] : this.paymentDateISO;
    this.apiStore
      .apiStatApi()
      .statPaymentsOfficesPlot(branchOfficeCode, undefined, undefined, currentDate.from, currentDate.to)
      .then(res => {
        this.setResultPaymentPlotDivisions(res.data.statPlot);
      })
      .catch(err => {
        console.warn('loadStatPaymentPlotOffices', err);
      });
  }
  loadStatPaymentPlotDepartments(departmentCode: Code = undefined, branchOfficeCode: Code = undefined): void {
    const currentDate = this.isCHartsWithPagination ? this.periodByMonth[this.chartsPaginationIndex] : this.paymentDateISO;
    this.apiStore
      .apiStatApi()
      .statPaymentsDepartmentsPlot(undefined, undefined, currentDate.from, currentDate.to, branchOfficeCode, departmentCode)
      .then(res => {
        this.setResultPaymentPlotDivisions(res.data.statPlot);
      })
      .catch(err => {
        console.warn('loadStatPaymentPlotDepartments', err);
      });
  }
  loadStatPaymentPlotEmployees(employeeCode: Code): void {
    const currentDate = this.isCHartsWithPagination ? this.periodByMonth[this.chartsPaginationIndex] : this.paymentDateISO;
    this.apiStore
      .apiStatApi()
      .statPaymentsEmployeesPlot(employeeCode, undefined, undefined, currentDate.from, currentDate.to)
      .then(res => {
        this.setResultPaymentPlotDivisions(res.data.statPlot);
      })
      .catch(err => {
        console.warn('loadStatPaymentPlotEmployees', err);
      });
  }
  loadStatPaymentPlotCompany(): void {
    const currentDate = this.isCHartsWithPagination ? this.periodByMonth[this.chartsPaginationIndex] : this.paymentDateISO;
    this.apiStore
      .apiStatApi()
      .statPaymentsHeadCompaniesPlot(undefined, undefined, currentDate.from, currentDate.to)
      .then(res => {
        this.setResultPaymentPlotDivisions(res.data.statPlot);
      })
      .catch(err => {
        console.warn('loadStatPaymentPlotCompany', err);
      });
  }

  async loadTotalPaymentData(mode: PaymentRoleEmployee): Promise<void> {
    if (
      // this.subjectsTotalReportsDataModeCode === this.selectedCrumbs[mode]?.code ||
      this.isTotalPaymentDataLoading ||
      !this.selectedCrumbs[mode]
    ) {
      return;
    }
    this.isTotalPaymentDataLoading = true;
    const currentDate = this.paymentDateISO;
    let totalRes;
    try {
      if (mode === 'company') {
        totalRes = await this.apiStore
          .apiStatApi()
          .statPaymentsDivisions(
            undefined,
            undefined,
            undefined,
            undefined,
            currentDate.from,
            currentDate.to,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            'planned'
          );
      } else if (mode === 'division') {
        totalRes = await this.apiStore
          .apiStatApi()
          .statPaymentsOffices(
            undefined,
            undefined,
            undefined,
            undefined,
            currentDate.from,
            currentDate.to,
            this.selectedCrumbs.division.code,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            'planned'
          );
      } else if (mode === 'office') {
        totalRes = await this.apiStore
          .apiStatApi()
          .statPaymentsDepartments(
            undefined,
            undefined,
            undefined,
            undefined,
            currentDate.from,
            currentDate.to,
            undefined,
            undefined,
            this.selectedCrumbs.office.code,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            'planned'
          );
      } else if (mode === 'department') {
        totalRes = await this.apiStore
          .apiStatApi()
          .statPaymentsEmployees(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            currentDate.from,
            currentDate.to,
            undefined,
            this.selectedCrumbs.department.code,
            undefined,
            'planned'
          );
      }
    } catch (e) {
      console.warn('loadTotalPaymentData error', e);
    }
    // загрузка подчиненных документов
    if (totalRes?.data) {
      const reportList = {};
      const totalResKeys = Object.keys(totalRes.data);
      totalRes?.data.stats?.forEach(item => {
        const totalResStatsKeys = Object.keys(item);
        reportList[item[totalResStatsKeys[0]]] = { ...item.report };
      });
      this.setSubjectsTotalReportsData(totalRes.data[totalResKeys[0]], reportList, mode);
    } else {
      this.isTotalPaymentDataLoading = false;
    }
  }

  setSubjectsTotalReportsData(
    items: { code?: string; title?: string }[],
    reportList: Record<string, StatPaymentsReport>,
    mode: PaymentRoleEmployee
  ): void {
    this.subjectsTotalPaymentDataByCode = {};
    items?.forEach(item => {
      this.subjectsTotalPaymentDataByCode[item.code] = {
        title: item.title,
        paidAmount: reportList[item.code].paidAmount,
        awaitingAmount: reportList[item.code].awaitingAmount,
        overdueAmount: reportList[item.code].overdueAmount,
      };
    });
    this.subjectsTotalReportsDataModeCode = this.selectedCrumbs[mode]?.code;
    this.isTotalPaymentDataLoading = false;
  }

  setResultPaymentPlotDivisions(plot: PlotRequest): void {
    const paymentPlot = plot;

    if (!paymentPlot.series.length || !paymentPlot.series[0].data?.length) {
      console.warn('payment plot has no data');
      return;
    }
    runInAction(() => {
      if (!this.statPlot.length) {
        const key = this.selectedCrumbs[this.role || this.mode]?.code;
        const fakePoints = [];
        const fakeEnd = new Date().getTime();
        for (let i = 90; i > 0; i--) {
          fakePoints.push({ x: fakeEnd - 24 * 60 * 60 * 1000 * i, y: 0 });
        }
        [1, 2, 3].forEach(() => {
          this.statPlot.push(<Plot>{
            name: key,
            points: fakePoints,
            xaxis: {},
            yaxis: {},
          });
        });
      }
      paymentPlot.series.forEach((item, itemIndex) => {
        let midVal = 0;
        let i = 0;
        item?.data?.forEach((point: number[], j: number, arr): void => {
          this.statPlot[itemIndex].points[j] = {
            x: point[0],
            y: point[1],
          } as PlotPoint;
          if (j >= arr.length - 20 && point[1] !== 0 && j !== arr.length - 1) {
            midVal += point[1];
            i += 1;
          }
        });
        this.statPlot[itemIndex].name = item.label;
        if (i) {
          this.statPlot[itemIndex].middleVal = midVal / i;
        }
        this.statPlot[itemIndex].points.splice(paymentPlot.series[0].data?.length);
        if (paymentPlot.xaxis) {
          this.statPlot[itemIndex].xaxis.minTicks = paymentPlot.xaxis.minTicks ?? undefined;
          this.statPlot[itemIndex].xaxis.maxTicks = paymentPlot.xaxis.maxTicks ?? undefined;
        } else {
          this.statPlot[itemIndex].xaxis = {};
        }
        this.statPlot[itemIndex].loaded = true;
      });
      runInAction(() => {
        this.isCHartsPaginationLoading = false;
        this.isCHartsLoading = false;
        this.chartWasChanged = !this.chartWasChanged;
      });
    });
  }

  async loadStatPaymentCompany(): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const currentDate = this.paymentDateISO;

    try {
      const res = await this.apiStore
        .apiStatApi()
        .statPaymentsHeadCompanies(
          undefined,
          undefined,
          undefined,
          undefined,
          currentDate.from,
          currentDate.to,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          'planned'
        );
      this.setResultPaymentDivisions(res);
    } catch (err) {
      console.warn('loadStatPaymentCompany', err);
    }
  }

  async loadStatPaymentEmployees(employeeCode: Code, departmentCode: Code = undefined): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const currentDate = this.paymentDateISO;
    try {
      const res = await this.apiStore
        .apiStatApi()
        .statPaymentsEmployees(
          employeeCode,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          currentDate.from,
          currentDate.to,
          departmentCode,
          undefined,
          undefined,
          'planned'
        );

      this.setResultPaymentEmployees(res);
    } catch (err) {
      console.warn('loadStatPaymentEmployees', err);
    }
  }

  async loadStatPaymentDepartments(departmentCode: Code = undefined, branchOfficeCode: Code = undefined): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const currentDate = this.paymentDateISO;
    try {
      const res = await this.apiStore
        .apiStatApi()
        .statPaymentsDepartments(
          undefined,
          undefined,
          undefined,
          undefined,
          currentDate.from,
          currentDate.to,
          undefined,
          departmentCode,
          branchOfficeCode,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          'planned'
        );
      this.setResultPaymentDepartments(res);
    } catch (err) {
      console.warn('loadStatPaymentDepartments', err);
    }
  }

  async loadStatPaymentOffices(branchOfficeCode: Code = undefined, divisionCode: Code = undefined): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const currentDate = this.paymentDateISO;
    try {
      const res = await this.apiStore
        .apiStatApi()
        .statPaymentsOffices(
          undefined,
          undefined,
          undefined,
          undefined,
          currentDate.from,
          currentDate.to,
          divisionCode,
          branchOfficeCode,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          'planned'
        );
      this.setResultPaymentOffices(res);
    } catch (err) {
      console.warn('loadStatPaymentOffices', err);
    }
  }

  async loadStatPaymentDivisions(divisionCode: Code): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    const currentDate = this.paymentDateISO;
    try {
      const res = await this.apiStore
        .apiStatApi()
        .statPaymentsDivisions(
          undefined,
          undefined,
          undefined,
          undefined,
          currentDate.from,
          currentDate.to,
          divisionCode,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          'planned'
        );
      this.setResultPaymentDivisions(res);
    } catch (err) {
      console.warn('loadStatPaymentDivisions', err);
    }
  }

  setResultPaymentDivisions({ data }: AxiosResponse<StatPaymentsDivisionsResponse | StatPaymentsHeadCompaniesResponse>): void {
    this.setPaymentStats(data);
  }
  setResultPaymentEmployees({ data }: AxiosResponse<StatPaymentsEmployeesResponse>): void {
    this.setPaymentStats(data);
  }
  setResultPaymentDepartments({ data }: AxiosResponse<StatPaymentsDepartmentsResponse>): void {
    this.setPaymentStats(data);
  }
  setResultPaymentOffices({ data }: AxiosResponse<StatPaymentsOfficesResponse>): void {
    this.setPaymentStats(data);
  }
  /**
   * Смена таба
   */
  setSelectedTab(value: PaymentTabNames): void {
    this.selectedTab = value;
    this.setRequestFromObj(observable({ ...this.request, tab: value }));
    this.actualizeRouter(this.request);
  }

  /**
   * Клик по крошке без меню
   */
  async setModeAndUpdateCrumbs(crumbName: PaymentRoleEmployee): Promise<void> {
    this.isTransactionsListLoading = true;
    this.isAwaitingListLoading = true;
    this.isOverdueListLoading = true;
    this.selectedTab = 'transactions';
    const prevMode = this.mode;
    const selectedCrumb = this.selectedCrumbs[crumbName];
    if (!this.setMode(crumbName)) {
      return;
    }
    runInAction(() => {
      this.isCHartsLoading = true;
    });
    if (crumbName !== PaymentRoleEmployeeTypes[PaymentRoleEmployeeTypes.length - 1] && crumbName !== this.role) {
      await this.loadStatPaymentReferences({ [crumbName]: selectedCrumb.code });
    }
    this.unSelectCrumbs(prevMode, crumbName);
    runInAction(() => {
      this.allowTabs = allowTabsByRole[crumbName];
    });
    this.loadCharts();
    this.setRequestFromObj(observable({ ...this.request, mode: crumbName, selectedCrumbCode: selectedCrumb?.code }));
    this.actualizeRouter(this.request);

    await this.loadStatBudgetGroups(crumbName);
    this.allowTabs.forEach(async item => {
      await this.loadStatByTab(item);
    });
  }

  /**
   * Выбираем значение из списка крошки.PaymentRoleEmployee
   */
  async setSelectedCrumbAndSetMode(crumbName: PaymentRoleEmployee, code: Code): Promise<void> {
    this.isTransactionsListLoading = true;
    this.isAwaitingListLoading = true;
    this.isOverdueListLoading = true;
    this.selectedTab = 'transactions';
    const prevMode = this.mode;
    this.currentCrumbsCode = code;
    const prevSelectedCrumbsCode = this.selectedCrumbs[crumbName]?.code;
    const selectedCrumb = this.crumbsValues[crumbName].find(crumb => crumb.code === code);
    if (!this.setMode(crumbName) && prevSelectedCrumbsCode === selectedCrumb.code) {
      return;
    }
    runInAction(() => {
      this.isLoading = true;
      this.isCHartsLoading = true;
      this.isLoaded = false;
    });
    this.setRequestFromObj(observable({ ...this.request, mode: crumbName, selectedCrumbCode: selectedCrumb.code }));
    this.actualizeRouter(this.request);

    // // * если роль последняя то обновляем References(т.к. следующей крошки после этой роли нет, значит можно не обновлять данные)
    if (crumbName !== PaymentRoleEmployeeTypes[PaymentRoleEmployeeTypes.length - 1]) {
      await this.loadStatPaymentReferences({ [crumbName]: selectedCrumb.code });
    }
    this.unSelectCrumbs(prevMode, crumbName);
    runInAction(() => {
      this.allowTabs = allowTabsByRole[crumbName];
      this.selectedCrumbs[crumbName] = selectedCrumb;
    });
    this.loadCharts();
    await this.loadStatBudgetGroups(crumbName);
    this.allowTabs.forEach(async item => {
      await this.loadStatByTab(item);
    });
  }
  actualizeRouter(request: AllowedParams): void {
    if (!this.routerControlEnabled) {
      return;
    }
    this.setRequestFromObj(request);
    const req = toJS(request);
    this.routerStore.replace(
      {
        pathname: this.routerStore.router.pathname,
        query: { ...req },
      },
      undefined,
      { shallow: true }
    );
  }
  async loadStatByTab(tab: PaymentTabNames): Promise<void> {
    const role = this.mode;
    this.expectationPage = 1;
    this.transactionsPage = 1;
    this.customersPaymentStatList = {};

    switch (tab) {
      case PAYMENT_TAB_NAME_CODES.clients:
        await this.loadStatClients(role);
        await this.loadStatBudgetGroups(role);
        break;
      case PAYMENT_TAB_NAME_CODES.transactions:
        this.loadPaymentsTransactions(role);
        break;
      case PAYMENT_TAB_NAME_CODES.awaiting:
        this.loadPaymentsAwaiting(role);
        break;
      case PAYMENT_TAB_NAME_CODES.overdue:
        this.loadPaymentsOverdue(role);
        break;
      default:
        console.warn('tab received does not exist');
        break;
    }
  }
  setRequestFromObj(req: AllowedParams): void {
    set(this.request, req);
  }
  lazyLoadExpectations(): void {
    if (!this.canLoadMore || this.isLoading) {
      return;
    }
    const role = this.mode;
    this.expectationPage++;
    this.loadStatClients(role);
  }
  lazyLoadTransactions(): void {
    if (!this.canLoadMoreTransactions || this.isTransactionsListLoading) {
      return;
    }
    const role = this.mode;
    this.transactionsPage++;
    this.loadPaymentsTransactions(role);
  }
  lazyLoadAwaiting(): void {
    if (!this.canLoadMoreAwaiting || this.isAwaitingListLoading) {
      return;
    }
    const role = this.mode;
    this.awaitingPage++;
    this.loadPaymentsAwaiting(role);
  }
  lazyLoadOverdue(): void {
    if (!this.canLoadMoreOverdue || this.isOverdueListLoading) {
      return;
    }
    const role = this.mode;
    this.overduePage++;
    this.loadPaymentsOverdue(role);
  }
  updateStats(): void {
    //* при обновлении даты запрашиваем еще новые данные для крошек, т.к. со временем могут добавляться новые менеджеры к отделу и новые отделы
    if (this.mode === PAYMENT_ROLE_EMPLOYEE_CODES.department || this.mode === PAYMENT_ROLE_EMPLOYEE_CODES.office) {
      this.loadStatPaymentReferences({ [this.mode]: this.selectedCrumbs[this.mode]?.code });
    }

    const currentPeriod = this.paymentDateISO;
    if (moment(currentPeriod.from).add(1, 'month').toDate() < new Date(currentPeriod.to)) {
      runInAction(() => {
        this.isCHartsWithPagination = true;
        this.chartsPaginationIndex = 0;
      });
      this.makeChartsInnerPeriods();
    } else {
      runInAction(() => {
        this.isCHartsWithPagination = false;
      });
      this.loadCharts();
    }
    this.loadStatBudgetGroups(this.mode);
    this.allowTabs.forEach(async item => {
      await this.loadStatByTab(item);
    });
  }

  async loadStatClients(role: PaymentRoleEmployee): Promise<void> {
    switch (role) {
      case PAYMENT_ROLE_EMPLOYEE_CODES.division:
        await this.loadStatExpectations();
        break;
      case PAYMENT_ROLE_EMPLOYEE_CODES.office:
        await this.loadStatExpectations();
        break;
      case PAYMENT_ROLE_EMPLOYEE_CODES.department:
        await this.loadStatExpectations(undefined, undefined, this.selectedCrumbs[role]?.code);
        break;
      case PAYMENT_ROLE_EMPLOYEE_CODES.employee:
        await this.loadStatExpectations(this.selectedCrumbs[role]?.code, undefined, undefined);
        break;
      case PAYMENT_ROLE_EMPLOYEE_CODES.company:
        await this.loadStatExpectations(undefined, this.selectedCrumbs[role]?.code);
        break;
      default:
        console.warn('role received does not exist');
        break;
    }
  }
  async loadStatExpectations(
    employeeCode: Code = undefined,
    chiefEmployeeCode: Code = undefined,
    departmentCode: Code = undefined
  ): Promise<void> {
    if (this.mode !== 'employee' && this.mode !== 'department') {
      return;
    }
    runInAction(() => {
      this.isLoading = true;
    });
    const currentDate = this.paymentDateISO;
    try {
      const res = await this.apiStore
        .apiStatApi()
        .statPaymentsExpectations(
          employeeCode,
          undefined,
          undefined,
          undefined,
          currentDate.from,
          currentDate.to,
          chiefEmployeeCode,
          departmentCode,
          this.expectationPage
        );
      this.setResultCustomersPaymentStat(res.data);
    } catch (err) {
      console.warn('statPaymentsExpectations Error', err);
      runInAction(() => {
        this.canLoadMore = false;
        this.isLoading = false;
        this.isLoaded = true;
      });
    }
  }
  setResultCustomersPaymentStat(data: StatPaymentsExpectationsResponse): void {
    if (!data.customers) {
      // если data.customers === null - дошли до конца списка
      runInAction(() => {
        this.canLoadMore = false;
        this.isLoading = false;
        this.isLoaded = true;
      });
      return;
    }

    const result: CustomersPaymentStatType = {};
    this.customersPaymentStatItemCount = data.customersTotalCount;
    data.customers.forEach(customer => {
      if (!this.customersPaymentStatList[customer.code]) {
        result[customer.code] = {
          totalAwaitingAmount: 0,
          totalOverdueAmount: 0,
          totalPaidAmount: 0,
          shortTitle: customer?.shortTitle || customer?.title,
          mainEmployee: `${customer?.mainEmployee?.surname || ''} ${customer?.mainEmployee?.name || ''} ${
            customer?.mainEmployee?.patronymic || ''
          }`,
          companyFoundedDate: customer?.mainEmployeeJoinDate,
          notDistributedAmount: 0,
          bills: {},
        };
      }
    });
    if (data.notDistributed?.length > 0) {
      data.notDistributed.forEach(nds => {
        if (result[nds.customerCode]) {
          result[nds.customerCode].notDistributedAmount = nds.notDistributedAmount;
        }
      });
    }

    if (!data.stats) {
      // если data.stats === null - для текущей страницы сотрудников нет оплат
      Object.keys(result).forEach(item => {
        this.customersPaymentStatList[item] = result[item];
      });
      runInAction(() => {
        this.canLoadMore = Object.keys(this.customersPaymentStatList).length < data.customersTotalCount;
        this.isLoading = false;
        this.isLoaded = true;
      });
      return;
    }

    data.stats.forEach(stat => {
      if (result[stat.customerCode]) {
        result[stat.customerCode].totalPaidAmount += stat?.paidAmount || 0;
        result[stat.customerCode].totalOverdueAmount += stat?.overdueAmount || 0;
        result[stat.customerCode].totalAwaitingAmount += stat?.awaitingAmount || 0;
        if (result[stat.customerCode].bills[stat.billMdmCode]) {
          result[stat.customerCode].bills[stat.billMdmCode].push(stat);
        } else {
          result[stat.customerCode].bills[stat.billMdmCode] = [stat];
        }
      }
    });
    Object.keys(result).forEach(item => {
      this.customersPaymentStatList[item] = result[item];
    });

    runInAction(() => {
      this.canLoadMore = Object.keys(this.customersPaymentStatList).length < data.customersTotalCount;
      this.isLoading = false;
      this.isLoaded = true;
    });
  }

  unSelectCrumbs(prevMode: PaymentRoleEmployee, newMode: PaymentRoleEmployee): void {
    const rolePrevIndex = PaymentRoleEmployeeTypes.indexOf(prevMode);
    const roleIndex = PaymentRoleEmployeeTypes.indexOf(newMode);

    if (roleIndex < rolePrevIndex) {
      const unSelectedCrumb = PaymentRoleEmployeeTypes.slice(roleIndex + 1, PaymentRoleEmployeeTypes.length);
      unSelectedCrumb.forEach(crumb => {
        this.selectedCrumbs[crumb] = undefined;
      });
      this.enabledCrumbs = this.enabledCrumbs.filter(crumb => !unSelectedCrumb.includes(crumb));
    }
    for (let nextRoleIndex = roleIndex + 1; nextRoleIndex < PaymentRoleEmployeeTypes.length; nextRoleIndex++) {
      const nextRole = PaymentRoleEmployeeTypes[roleIndex + 1];
      if (nextRole && this.crumbsValues[nextRole].length >= 1) {
        if (this.crumbsValues[nextRole].length === 1) {
          // Выбираем в крошке единственный вариант
          this.selectedCrumbs[nextRole] = this.crumbsValues[nextRole][0];
        } else if (this.selectedCrumbs[nextRole]) {
          // Сбрасываем выбранный вариант, если его нет в доступном списке
          this.selectedCrumbs[nextRole] = this.crumbsValues[nextRole].find(crumb => crumb === this.selectedCrumbs[nextRole]);
        }
        this.enabledCrumbs.push(nextRole);
      } else {
        this.enabledCrumbs = this.enabledCrumbs.filter(crumb => crumb !== nextRole);
      }
    }
  }
  setPaymentStats(data): void {
    this.paymentStats = data.stats || [];
    this.isLoading = false;
  }

  setCrumbsValues(data: StatReferencesResponse): void {
    this.crumbsValues[PAYMENT_ROLE_EMPLOYEE_CODES.company] = data.headCompanies || [];
    this.crumbsValues[PAYMENT_ROLE_EMPLOYEE_CODES.division] = data.divisions || [];
    this.crumbsValues[PAYMENT_ROLE_EMPLOYEE_CODES.office] = [...(data.offices || [])].map(i => ({ ...i, title: i.name }));
    this.crumbsValues[PAYMENT_ROLE_EMPLOYEE_CODES.department] = data.departments || [];
    this.crumbsValues[PAYMENT_ROLE_EMPLOYEE_CODES.employee] = [...(data.employees || [])].map(employee => ({
      code: employee.code,
      title: `${employee.surname || ''} ${employee.name || ''} ${employee.patronymic || ''}`,
      departmentCode: employee.departmentCode,
    }));
  }

  async loadStatPaymentReferences(
    {
      employee: employeeCode,
      department: departmentCode,
      office: branchOfficeCode,
      division: divisionCode,
    }: {
      employee?: Code;
      department?: Code;
      office?: Code;
      division?: Code;
    },
    init = false
  ): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });
    try {
      const res = await this.apiStore
        .apiStatApi()
        .statMarginalityReferences(employeeCode, departmentCode, branchOfficeCode, divisionCode, this._dateFormatPayment.from);
      this.setResultMarginalityReferences(res, init);
    } catch (err) {
      console.warn('loadStatPaymentReferences', err);
    }
  }
  setResultMarginalityReferences({ data }: AxiosResponse<StatReferencesResponse>, init = false): void {
    this.setCrumbsValues(data);
    if (init) {
      const roleIndex = PaymentRoleEmployeeTypes.indexOf(this.role);
      const currentMode = this.mode; //* текущий мод из урла. Если нет то тут будет мод по роли и дальше все пойдет по маслу.
      const modeParamIndex = PaymentRoleEmployeeTypes.indexOf(currentMode);
      if (roleIndex <= modeParamIndex) {
        // * подгружаем данные в крошки, которые идут ДО крошки по моду (включительно)
        const crumbsBeforeRole = PaymentRoleEmployeeTypes.slice(0, modeParamIndex + 1).reverse();

        crumbsBeforeRole.forEach((crumbs, index) => {
          if (this.crumbsValues[crumbs].length > 0) {
            if (crumbs === this.mode) {
              //* ищем, для переданной крошки из урла, значение
              const valueByUrl = this.crumbsValues[crumbs].find(crumb => crumb.code === this.request.selectedCrumbCode);
              if (valueByUrl) {
                this.selectedCrumbs[crumbs] = valueByUrl;
              } else {
                //* может быть такое что не нашлось значение и просто берем по дефолту
                this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs][0];
              }
            } /* else if (crumbs == this.role) {
              //* если в урле ничего не передается берем по роли
              this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs][0];
            } */ else {
              //* собираем крошку НЕ переданной из урла и формируем ее на основе Переданной крошки, и остальные так же
              const selectedCrumbsBefore = this.selectedCrumbs[crumbsBeforeRole[index - 1]];
              const selectedCrumbsBeforeCode = selectedCrumbsBefore?.[getPropertyByEntity[crumbs]];
              //* бывает так когда нет у крошки ключа по которой можно ее найти (company не имеет связки с division)
              if (selectedCrumbsBeforeCode) {
                this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs].find(crumb => crumb.code === selectedCrumbsBeforeCode);
              } else {
                this.selectedCrumbs[crumbs] = this.crumbsValues[crumbs][0];
              }
            }
          } else {
            console.warn('crumbsValues with "crumbs" is not exist');
          }
        });
      } else {
        this.snackbarStore.showInfo('Вам недоступен просмотр этого уровня.');
        console.warn('Вам недоступен просмотр этого уровня');
      }
      this.enabledCrumbs = PaymentRoleEmployeeTypes.slice(roleIndex, modeParamIndex + 1);
      const nextMode = PaymentRoleEmployeeTypes[modeParamIndex + 1];
      if (nextMode && this.crumbsValues[nextMode].length >= 1) {
        if (this.crumbsValues[nextMode].length === 1) {
          this.selectedCrumbs[nextMode] = this.crumbsValues[nextMode][0];
        }
        this.enabledCrumbs.push(nextMode);
      }
    }
  }
}
